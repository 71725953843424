import bilAPI from '../api';

export const getReasonsToCreateStudent = ({ page, page_size }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/school_class/reasons_to_create_student/`, {
          params: {
            page,
            page_size,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const deleteReasonToCreateStudent = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/school_class/reasons_to_create_student/${uuid}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const postReasonToCreateStudent = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post('/school_class/reasons_to_create_student/', body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const patchReasonToCreateStudent = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/school_class/reasons_to_create_student/${uuid}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};
