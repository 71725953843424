import bilAPI from '../api';

export const getSalary = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/salary/salary_profile/${uuid}/get_salary/`)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const setSalary = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/salary/current_month/${uuid}/set_salary/`)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const setSalaries = () => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/salary/current_month/set_salary/`)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
