import bilAPI from '../services/api';
import i18n from '../lang/i18n';

export const phoneTypesOptions = [
  {
    key: 0,
    text: 'Own',
    value: 0,
  },
  {
    key: 1,
    text: 'Home',
    value: 1,
  },
  {
    key: 2,
    text: 'Work',
    value: 2,
  },
  {
    key: 3,
    text: 'Other',
    value: 3,
  },
];

export const genderOptions = [
  { key: 0, text: 'Male', value: 'M' },
  { key: 1, text: 'Female', value: 'F' },
];

export const languageLevelsOptions = [
  {
    key: 0,
    text: 'A1',
    secondary: 'Beginner',
    explanation: i18n.t('profile_page.languages.a1'),
    value: 0,
  },
  {
    key: 1,
    text: 'A2',
    secondary: 'Elementary',
    explanation: i18n.t('profile_page.languages.a2'),
    value: 1,
  },
  {
    key: 2,
    text: 'B1',
    secondary: 'Intermediate',
    explanation: i18n.t('profile_page.languages.b1'),
    value: 2,
  },
  {
    key: 3,
    text: 'B2',
    secondary: 'Upper Intermediate',
    explanation: i18n.t('profile_page.languages.b2'),
    value: 3,
  },
  {
    key: 4,
    text: 'C1',
    secondary: 'Advanced',
    explanation: i18n.t('profile_page.languages.c1'),
    value: 4,
  },
  {
    key: 5,
    text: 'C2',
    secondary: 'Proficient',
    explanation: i18n.t('profile_page.languages.c2'),
    value: 5,
  },
];

export const bilTeacherStatus = [
  {
    key: 1,
    text: i18n.t('profile_page.bil_rotation'),
    value: 1,
  },
  {
    key: 2,
    text: i18n.t('profile_page.bil_local'),
    value: 2,
  },
  {
    key: 3,
    text: i18n.t('profile_page.local'),
    value: 3,
  },
];

export const getCities = (province) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/common/cities/', { params: { province } })
        .then((res) => {
          resolve(res.data.results);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getEducationDegrees = () => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/education/education_degrees/')
        .then((res) => {
          resolve(res.data.results);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getLanguages = () => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/common/languages/')
        .then((res) => {
          resolve(res.data.results);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getNationalities = () => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/common/nationalities/')
        .then((res) => {
          resolve(res.data.results);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getPositions = () => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/education/positions/', { params: { page_size: 100 } })
        .then((res) => {
          resolve(res.data.results);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getPositionsToSchool = () => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/education/positions/position_to_school/', {
          params: { page_size: 100 },
        })
        .then((res) => {
          resolve(res.data.results);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getHierarchicalPositions = () => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/education/hierarchical_positions/', {
          params: { page_size: 50 },
        })
        .then((res) => {
          resolve(res.data.results);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getRegions = () => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/common/regions/')
        .then((res) => {
          resolve(res.data.results);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getSchool = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/education/schools/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getSchools = (
  region,
  org_type,
  system_type,
  group,
  school_olympiad_type,
  region_codes,
) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/education/schools/', {
          params: {
            region,
            org_type,
            system_type,
            region_codes,
            group,
            school_olympiad_type,
            page_size: 80,
          },
        })
        .then((res) => {
          resolve(res.data.results);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getSpecialities = () => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/education/specialities/')
        .then((res) => {
          resolve(res.data.results);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getTeacherCategories = () => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/education/teacher_categories/')
        .then((res) => {
          resolve(res.data.results);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getTeacherStatuses = () => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/common/statuses/')
        .then((res) => {
          resolve(res.data.results);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getUniversities = (name) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/education/universities/', { params: { name } })
        .then((res) => {
          resolve(res.data.results);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getCountries = ({ name, page_size }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/common/countries/', { params: { name, page_size } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getProvinces = (country) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/common/provinces/', { params: { country } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getAcademicYears = (country) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/education/academic_year/')
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};
