import bilAPI from '../api';

export const getSubjectsReportsOfSchool = ({ periods, tasks, school, ordering}) => {
  return new Promise((resolve, reject) => {
    console.log(`/olympiad/olympiad_subject_reports?period=${periods}&tasks=${tasks}&school=${school}`)
    try {
      bilAPI
        .get(`/olympiad/olympiad_subject_reports?`, {params : {periods, tasks, school, ordering}})
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject('system error');
    }
  });
};
