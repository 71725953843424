import bilAPI from "../api";

export const getLanguageGroups = () => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/education/school_groups/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("axios error");
        });
    } catch (error) {
      reject("system error");
    }
  });
};
