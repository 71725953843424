import bilAPI from '../api';

export const getPsychologyQuestions = (test) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/psychology/questions/`, { params: { test, page_size: 200 } })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const postPsychologyQuestion = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/psychology/questions/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const patchPsychologyQuestion = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/psychology/questions/${uuid}/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const deletePsychologyQuestion = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/psychology/questions/${uuid}/`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getPsychologyQuestionTypes = ({ test_types }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/psychology/question_types/`, {
          params: { test_types, page_size: 200 },
        })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getPsychologyAccountQuestionAnswers = (attempt) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`psychology/account_question_answer/`, {
          params: { attempt, page_size: 200 },
        })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject('system');
    }
  });
};
