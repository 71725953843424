import bilAPI from '../api';

export const getGoals = ({
  uuid,
  account,
  recipient,
  type,
  goal,
  priority,
  status,
  page_size,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/common/goals/`, {
          params: {
            uuid,
            account,
            recipient,
            type,
            goal,
            priority,
            status,
            page_size,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const postGoals = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/common/goals/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const patchGoals = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/common/goals/${uuid}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const patchGoalsWithRecipient = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/common/goals/${uuid}/assign_recipient/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const deleteGoal = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/common/goals/${uuid}/`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getGoalsTypes = (name) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/common/goal_types/`, {
          params: {
            name,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const generateChecklist = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/common/generate_checklist/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getReceivers = (name) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/school/teachers_search/`, {
          params: {
            name,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getTeachersChecklist = (params) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/school/teachers_checklist/`, {
          params,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
