import { BellIcon, EyeIcon } from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { getUserAnnouncements } from '../../services';
import Button from '../Buttons/Button';

export default function NotificationsDropdown() {
  let history = useHistory();

  const [open, setOpen] = useState(true);
  const [notifications, setNotifications] = useState([]);
  // const [count, setCount] = useState(0);

  useEffect(() => {
    getUserAnnouncements().then((res) => {
      const array = Object.keys(res).map((key) => res[key]);
      setNotifications(array);
      // setCount(res.count);
    });

    return () => {};
  }, []);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      await getUserAnnouncements().then((res) => {
        // if (res.count > count && count !== 0) {
        const array = Object.keys(res).map((key) => res[key]);
        setNotifications(array);
        // setCount(res.count);
        // clearInterval(intervalId);
        // }
      });
    }, 60000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const getType = (type) => {
    if (type === 0) {
      // Announcement
      return { type: 'Announcement', color: 'bg-blue-500' };
    }
    if (type === 1) {
      // Notification
      return { type: 'Notification', color: 'bg-gray-500' };
    }
    if (type === 2) {
      // Congratulation
      return { type: 'Congratulation', color: 'bg-green-500' };
    }
    if (type === 3) {
      // Warning
      return { type: 'Warning', color: 'bg-red-500' };
    }
    if (type === 4) {
      // Warning
      return { type: 'Reminder', color: 'bg-orange-500' };
    }
  };

  const redirectToPost = (uuid) => {
    setOpen((prev) => !prev);
    history.push({
      pathname: `/announcement/post/${uuid}`,
    });
  };

  return (
    <div>
      <button
        className='inline-flex items-center text-sm font-medium text-center text-gray-500 hover:text-gray-900 focus:outline-none '
        type='button'
        onClick={() => setOpen((prev) => !prev)}
      >
        <BellIcon className='w-6 h-6' />
        <div className='flex relative'>
          {!!notifications?.length && (
            <div className='inline-flex relative -top-2 right-3 w-3 h-3 bg-red-500 rounded-full border-2 border-white dark:border-gray-900' />
          )}
        </div>
      </button>
      <div
        className={`${
          open && 'hidden'
        } fixed right-16 z-40 w-full max-w-sm bg-white rounded divide-y divide-gray-100 shadow`}
      >
        <div className='block py-2 px-4 font-medium text-center text-gray-700 bg-gray-50 '>
          Notifications
        </div>
        <div className='divide-y divide-gray-100 h-96 overflow-y-scroll'>
          {notifications.map((notification) => (
            <div key={notification?.uuid} className='flex py-3 px-4'>
              <div className='pl-3 w-full'>
                <div className='text-gray-500 mb-1.5 dark:text-gray-400'>
                  Message: {notification?.title}
                </div>
                <div className='flex flex-row justify-between'>
                  <span
                    className={
                      getType(notification?.type).color +
                      ' text-white rounded-lg text-sm px-2 py-1'
                    }
                  >
                    {getType(notification?.type).type}
                  </span>
                  <Button
                    Icon={EyeIcon}
                    onClick={() => {
                      redirectToPost(notification?.uuid);
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
        <Link to='/announcement/feed'>
          <button
            className='block w-full py-2 text-sm font-medium text-center text-gray-900 bg-gray-50 hover:bg-gray-100'
            onClick={() => setOpen((prev) => !prev)}
          >
            <div className='inline-flex items-center'>View all</div>
          </button>
        </Link>
      </div>
    </div>
  );
}
