import bilAPI from '../api';

export const getRegionalMedalRating = ({ uuid, subject, grade, page_size }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/exams/regional_medal_rating/${uuid}`, {
          params: { subject, grade, page_size },
        })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
