import bilAPI from '../api';

export const getStudentsSeen = ({
  page,
  page_size,
  exam_collection,
  ordering,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/exams/school_students_seen/`, {
          params: { page, page_size, exam_collection, ordering },
        })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
