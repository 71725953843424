import bilAPI from '../api';

// indecator: 0 - annual, 1 - semiannual
export const getAcademicGoalTotalReport = ({
  indecator,
  page_size = 60,
  ordering,
  academic_year,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/education/schools_academic_goal_total_report`, {
          params: { indecator, page_size, ordering, academic_year },
        })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
