import bilAPI from '../api';

export const getTeachersPedagogicalLevel = (params) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/ped_level_tracking/ped_level/', { params })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getPedagogicalLevel = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/ped_level_tracking/ped_level/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const postPedagogicalLevel = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post('/ped_level_tracking/ped_level/', body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const patchPedagogicalLevel = (levelUuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/ped_level_tracking/ped_level/${levelUuid}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const deletePedagogicalLevel = (levelUuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/ped_level_tracking/ped_level/${levelUuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getPedLevelTeachersList = (params) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/ped_level_tracking/teachers_list', { params })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getPedLevelSchoolsList = (params) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/ped_level_tracking/schools_list', { params })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getPedLevelRegionsList = ({
  page,
  page_size,
  bil_teacher_status,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/ped_level_tracking/regions_list', {
          params: { page, page_size, bil_teacher_status },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getPedLevelAllReport = ({ bil_teacher_status }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/ped_level_tracking/all_report', {
          params: { bil_teacher_status },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};
