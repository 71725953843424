import bilAPI from '../api';

export const getDocs = ({ account }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/salary/my_docs/`, { params: { account } })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getDocsByUuid = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/salary/my_docs/${uuid}`)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const postDocs = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/salary/my_docs/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const deleteDocs = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/salary/my_docs/${uuid}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const patchDocs = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/salary/my_docs/${uuid}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
