import bilAPI from '../api';

export const getPsychologyClassReports = ({ page, page_size }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/psychology/class_reports/`, {
          params: { page, page_size },
        })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getOrCreatePsychologyClassReports = ({
  page,
  page_size,
  academic_year,
  academic_year_term,
  school,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/psychology/get_or_create_school_class_reports/`, {
          params: {
            page,
            page_size,
            academic_year,
            academic_year_term,
            school,
          },
        })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getPsychologyClassReport = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/psychology/class_reports/${uuid}/`)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const postPsychologyClassReport = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/psychology/class_reports/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const patchPsychologyClassReport = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/psychology/class_reports/${uuid}/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const deletePsychologyClassReport = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/psychology/class_reports/${uuid}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
