import { Grid, Loader } from 'semantic-ui-react';
// import '../../style.css';

export default function FullscreenLoader() {
  return (
    <Grid className='full-screen'>
      <Grid.Column verticalAlign='middle'>
        <Loader active inline='centered' size='big' />
      </Grid.Column>
    </Grid>
  );
}
