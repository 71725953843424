import bilAPI from '../api';

export const getTeachers = (params) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/school/teachers/', {
          params,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getTeachersExcelFile = (params) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/school/teacher_passwords_excel_create/', {
          params,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const createTeacher = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post('/school/school_admin_create_teacher/', body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getTeachersInfoExcel = (params) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/school/teacher_account_full_information_excel/', {
          params,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};
