import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import axios from 'axios';
import 'semantic-ui-css/semantic.min.css';
import './index.css';

axios.defaults.headers.post['Content-Type'] = 'application/json';

ReactDOM.render(<App />, document.querySelector('#root'));
