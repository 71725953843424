import bilAPI from '../api';

export const getCurrentMonths = ({ school, iin, page, page_size }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/salary/current_month/`, {
          params: { school, iin, page, page_size },
        })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const patchCurrentMonth = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/salary/current_month/${uuid}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const postArchive = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/salary/current_month/set_salary_history/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
