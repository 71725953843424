import bilAPI from '../api';

export const getTeacherSimulationList = (params) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/school/teacher_transfer/`, { params })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const migrateAllTeachers = () => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/school/transfer_simulation/migrate/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const migrateSchoolTeachers = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/school/transfer_simulation/${uuid}/migrate/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const removeTeacher = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/school/transfer_simulation/remove/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const backToPrevSchoolTeacher = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/school/transfer_simulation/back_to_previous_school/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const createSimulationTeacher = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post('/school/simulation_create_teacher/', body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getTransfersSimulationExcel = (params) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/school/simulation_transfers_excel_create/', {
          params,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const addAccount = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/school/transfer_simulation/add_account/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};
