import bilAPI from '../api';

export const getLessonContents = (lessonUuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/courses/content_parts/`, {
            params: {
                lesson: lessonUuid
            }
        })
        .then((res) => {
          resolve(res.data.results);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

  export const createLessonContent = (body) => {
    return new Promise((resolve, reject) => {
      try {
        bilAPI
          .post('/courses/content_parts/', body)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      } catch (error) {
        reject(error);
      }
    });
  };
  
  export const updateLessonContent = (contentUuid, body) => {
    return new Promise((resolve, reject) => {
      try {
        bilAPI
          .patch(`/courses/content_parts/${contentUuid}/`, body)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      } catch (error) {
        reject(error);
      }
    });
  };

  export const removeLessonContent = (contentUuid) => {
    return new Promise((resolve, reject) => {
      try {
        bilAPI
          .delete(`/courses/content_parts/${contentUuid}`
          )
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject('axios error');
          });
      } catch (error) {
        reject('system error');
      }
    });
  };

  //  export const getVideoContent = (lessonUuid) => {
  //   return new Promise((resolve, reject) => {
  //     try {
  //       bilAPI
  //         .get(`/courses/content_parts/`, {
  //           params: {
  //               lesson: lessonUuid
  //           }
  //         })
  //         .then((res) => {
  //           let filteredObj = [];
  //           res.data.results.map((lessonContent) => {
  //             if (!!lessonContent.video_url && lessonContent.type === 1) filteredObj.push(lessonContent);
  //           })
  //           resolve(filteredObj);
  //         })
  //         .catch((err) => {
  //           reject('axios error');
  //         });
  //     } catch (error) {
  //       reject('system error');
  //     }
  //   });
  // };

  // export const getTextContent = (lessonUuid) => {
  //   return new Promise((resolve, reject) => {
  //     try {
  //       bilAPI
  //         .get(`/courses/content_parts/`, {
  //           params: {
  //               lesson: lessonUuid
  //           }
  //         })
  //         .then((res) => {
  //           let filteredObj = [];
  //           res.data.results.map((lessonContent) => {
  //             if (!!lessonContent.text_content && lessonContent.type === 0) {
  //               filteredObj.push(lessonContent);
  //             }
  //           })
  //           resolve(filteredObj);
  //         })
  //         .catch((err) => {
  //           reject('axios error');
  //         });
  //     } catch (error) {
  //       reject('system error');
  //     }
  //   });
  // };

  // export const getFileContent = (lessonUuid) => {
  //   return new Promise((resolve, reject) => {
  //     try {
  //       bilAPI
  //         .get(`/courses/content_parts/`, {
  //           params: {
  //               lesson: lessonUuid
  //           }
  //         })
  //         .then((res) => {
  //           let filteredObj = []
  //           res.data.results.map((lessonContent) => {
  //             if (!!lessonContent.file) filteredObj.push(lessonContent);
  //           })
  //           resolve(filteredObj)
  //         })
  //         .catch((err) => {
  //           reject('axios error');
  //         });
  //     } catch (error) {
  //       reject('system error');
  //     }
  //   });
  // };

  // export const getQuestionContent = (lessonUuid) => {
  //   return new Promise((resolve, reject) => {
  //     try {
  //       bilAPI
  //         .get(`/courses/content_parts/`, {
  //           params: {
  //               lesson: lessonUuid
  //           }
  //         })
  //         .then((res) => {
  //           let filteredObj = []
  //           res.data.results.map((lessonContent) => {
  //             if (lessonContent.type === 5) filteredObj.push(lessonContent);
  //           })
  //           resolve(filteredObj)
  //         })
  //         .catch((err) => {
  //           reject('axios error');
  //         });
  //     } catch (error) {
  //       reject('system error');
  //     }
  //   });
  // };

  // export const getRatingContent = (lessonUuid) => {
  //   return new Promise((resolve, reject) => {
  //     try {
  //       bilAPI
  //         .get(`/courses/content_parts/`, {
  //           params: {
  //               lesson: lessonUuid
  //           }
  //         })
  //         .then((res) => {
  //           let filteredObj = []
  //           res.data.results.map((lessonContent) => {
  //             if (lessonContent.type === 6) filteredObj.push(lessonContent);
  //           })
  //           resolve(filteredObj)
  //         })
  //         .catch((err) => {
  //           reject('axios error');
  //         });
  //     } catch (error) {
  //       reject('system error');
  //     }
  //   });
  // };