import bilAPI from '../api';

export const getExamCollections = ({
  academic_year,
  exam_type,
  type_code,
  ordering,
  exam_type_exclude,
  account_uuid,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/exams/exams_collections/`, {
          params: {
            academic_year,
            exam_type,
            type_code,
            ordering,
            exam_type_exclude,
            account_uuid,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getExamCollection = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/exams/exams_collections/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const postExamCollections = (date, name, exam_type) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/exams/exams_collections/`, date, name, exam_type)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const patchExamCollection = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/exams/exams_collections/${uuid}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
