import bilAPI from '../api';

export const getStudentProgressInfo = (accountUuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/courses/student_progress/`, {
            params: {
                account: accountUuid
            }
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};