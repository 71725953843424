import bilAPI from '../api';

export const getAcademicGoalTaskReports = () => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/education/academic_goal_task_report/`)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const patchAcademicGoalTaskReport = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/education/academic_goal_task_report/${uuid}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
