import bilAPI from '../api';

export const getExams = (exams_collection, page_size) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/exams/exams/`, { params: { exams_collection, page_size } })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getExam = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/exams/exams/${uuid}`)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const deleteExam = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/exams/exams/${uuid}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const postExam = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/exams/exams/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const putExam = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .put(`/exams/exams/${uuid}/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
