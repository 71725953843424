import bilAPI from '../api';

export const generateTemplate = ({ exam_collection, school, grade }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/exams/export_school_exam_sample/${exam_collection}/`, null, {
          params: {
            school,
            grade,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
