import bilAPI from '../api';

export const getPsychologyReportsByTeacher = ({
  page,
  page_size,
  terms,
  ordering,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/psychology/report_by_teacher`, {
          params: { page, page_size, terms, ordering },
        })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getPsychologyReportsByClass = ({
  uuid,
  school,
  grade,
  class_type,
  psychologist,
  page,
  page_size,
  terms,
  ordering,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/psychology/report_by_class`, {
          params: {
            uuid,
            school,
            grade,
            class_type,
            psychologist,
            page,
            page_size,
            terms,
            ordering,
          },
        })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject('system error');
    }
  });
};
