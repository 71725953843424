import bilAPI from "../api";

export const getExamCollectionSubjects = ({
  page,
  page_size,
  exam_collection,
  grade,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/exams/exam_collection_subjects/${exam_collection}/`, {
          params: { page, page_size, grade },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("axios error");
        });
    } catch (error) {
      reject("system error");
    }
  });
};
