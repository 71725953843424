import React from 'react';
import { useTranslation } from 'react-i18next';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru/index.js';
import kk from 'date-fns/locale/kk/index.js';
import enUS from 'date-fns/locale/en-US/index.js';
import Select from '../Select/Select';

export const systemLanguageOptions = [
  {
    key: 'kk',
    label: '🇰🇿 Қазақша',
    value: 'kk',
    flag: 'kz',
  },
  {
    key: 'en',
    label: '🇬🇧 English',
    value: 'en-US',
    flag: 'gb',
  },
  {
    key: 'ru',
    label: '🇷🇺 Русский',
    value: 'ru',
    flag: 'ru',
  },
];

export default function LanguageDropdown({ reloadOnChange = true }) {
  const [, i18n] = useTranslation();
  registerLocale('ru', ru);
  registerLocale('kk', kk);
  registerLocale('en', enUS);

  function handleLanguageChange(event) {
    i18n.changeLanguage(event.value);
    setDefaultLocale(event.value);
    if (reloadOnChange) {
      window.location.reload();
    }
  }

  return (
    <Select
      className='z-40'
      options={systemLanguageOptions}
      onChange={handleLanguageChange}
      value={i18n.language}
    />
  );
}
