import bilAPI from '../api';

export const getParticipatedGrades = (exams_collection) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/exams/get_participated_grades/${exams_collection}`, {
          params: {},
        })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
