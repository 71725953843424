import bilAPI from '../api';

export const getAnnouncements = (type, page, pageSize, author, nocontain) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/announcement/announcement/', {
          params: {
            author,
            nocontain,
            type,
            page: page,
            page_size: pageSize,
          },
        })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const postAnnouncement = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post('/announcement/announcement/', body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const patchAnnouncement = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/announcement/announcement/${uuid}/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getUserAnnouncements = (page, pageSize) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/announcement/announcement/get_user_messages/', {
          params: {
            page: page,
            page_size: pageSize,
          },
        })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getAnnouncement = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/announcement/announcement/${uuid}`)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const deleteAnnouncement = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/announcement/announcement/${uuid}`)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};
