import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';

const Select = ({
  options,
  className,
  placeholder,
  onChange,
  value,
  isDisabled,
  customStyles,
  isMulti,
  loading,
  closeMenuOnSelect,
  defaultValue,
  isClearable,
  bgGray,
}) => {
  const customStylesDefault = {
    singleValue: (provided, state) => {
      const opacity = typeof value === 'number' || value ? 1 : 0.5;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition, color: '#4b5563' };
    },
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: '#E9ECF5',
    }),
  };

  const [t] = useTranslation();
  const animatedComponents = makeAnimated();

  const getValue = () => {
    if (isMulti) {
      return options?.filter((o) => value?.includes(o?.value));
    }
    return options?.find((o) => o?.value === value);
  };

  return (
    <ReactSelect
      className={`relative ${!!isDisabled && 'opacity-50'} ` + className}
      styles={customStyles ? customStyles : customStylesDefault}
      options={options}
      placeholder={placeholder ?? t('component.select')}
      onChange={onChange}
      value={getValue()}
      isDisabled={isDisabled}
      isClearable={isClearable}
      isMulti={isMulti}
      isLoading={loading}
      closeMenuOnSelect={closeMenuOnSelect}
      defaultValue={getValue(isMulti, options, defaultValue)}
      components={isMulti ? animatedComponents : null}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: '#2C59F8',
          primary75: '#fb923c',
          primary50: '#cbd5e1',
          primary25: '#e2e8f0',
          neutral0: bgGray ? '#E9ECF5' : '#f7f7fa',
          neutral5: '',
          neutral10: '',
          neutral20: '',
          neutral30: '',
          neutral40: '',
          neutral50: '',
          neutral60: '',
          neutral70: '',
          neutral80: '',
          neutral90: '',
        },
      })}
    />
  );
};

export default Select;
