import bilAPI from '../api';

export const getOverviewByRegion = ({
  exam_collection,
  school_funding_type,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/exams/exams_overview_by_regions/${exam_collection}/`, {
          params: { school_funding_type },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
