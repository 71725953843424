import bilAPI from '../api';

export const getSkillAccessibilityByCourseId = (courseUuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/courses/course_accessibility/`, {
            params: {
                course: courseUuid
            }
        })
        .then((res) => {
          resolve(res.data.results);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

  export const postSkillAccessibility = (body) => {
    return new Promise((resolve, reject) => {
      try {
        bilAPI
          .post('/courses/course_accessibility/', body)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      } catch (error) {
        reject(error);
      }
    });
  };
  
  export const patchSkillAccessibility = (accessibilityUuid, body) => {
    return new Promise((resolve, reject) => {
      try {
        bilAPI
          .patch(`/courses/course_accessibility/${accessibilityUuid}/`, body)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      } catch (error) {
        reject(error);
      }
    });
  };

  // export const removeSkillAccessibility = (accessibilityUuid) => {
  //   return new Promise((resolve, reject) => {
  //     try {
  //       bilAPI
  //         .delete(`/courses/course_accessibility/${accessibilityUuid}/`
  //         )
  //         .then((res) => {
  //           resolve(res.data);
  //         })
  //         .catch((err) => {
  //           reject('axios error');
  //         });
  //     } catch (error) {
  //       reject('system error');
  //     }
  //   });
  // };