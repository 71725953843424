import bilAPI from '../api';

export const getRelativesInfo = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/school/account_relatives_info/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const patchRelativesInfo = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/school/account_relatives_info/${uuid}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const updateRelativesMainInfo = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/school/account_main_info/${uuid}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const deleteRelativeAccount = (uuid, relativeType, relativeUuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/auth/accounts/${uuid}/remove_relative/`, {
          relative_account_uuid: relativeUuid,
          type: relativeType,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const putRelativesInfo = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .put(`/school/account_relatives_info/${uuid}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
