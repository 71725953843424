import { useState, useEffect, useRef } from 'react';
import bilAPI from '../../services/api';

export default function useContactInfo(uuid) {
  const [contactInfo, setContactInfo] = useState();
  const isMounted = useRef(true);

  useEffect(() => {
    if (uuid) {
      getContactInfo(uuid);
    }
    // return () => {
    //   isMounted.current = false;
    // };
  }, [uuid]);

  const phoneTypes = [
    {
      key: 0,
      text: 'Own',
      value: 0,
    },
    {
      key: 1,
      text: 'Home',
      value: 1,
    },
    {
      key: 2,
      text: 'Work',
      value: 2,
    },
    {
      key: 3,
      text: 'Other',
      value: 3,
    },
  ];

  async function getContactInfo(uuid) {
    await bilAPI.get(`/school/account_contact_info/${uuid}/`).then((res) => {
      if (isMounted.current) setContactInfo(res.data);
    });
  }

  async function putContactInfo(uuid, body) {
    await bilAPI
      .put(`/school/account_contact_info/${uuid}/`, body)
      .then((res) => {
        if (isMounted.current) setContactInfo(res.data);
      });
  }

  async function patchContactInfo(uuid, body) {
    await bilAPI
      .patch(`/school/account_contact_info/${uuid}/`, body)
      .then((res) => {
        if (isMounted.current) setContactInfo(res.data);
      });
  }

  const createPhone = async (body) => {
    const response = await bilAPI.post('/common/create_phone/', body);

    return response.data;
  };

  return {
    contactInfo,
    phoneTypes,
    getContactInfo,
    putContactInfo,
    patchContactInfo,
    createPhone,
  };
}
