import React, { useState, useCallback } from 'react';
import Snackbar from '../../components/Snackbar/Snackbar';

const AUTO_DISMISS = 5000;

export const SnackbarContext = React.createContext({
  toast: null,
  addSnackbar: () => {},
  removeSnackbar: () => {},
});

export default function SnackbarProvider({ children }) {
  const [toast, setSnackbar] = useState(null);

  const removeSnackbar = () => setSnackbar(null);

  const addSnackbar = (message, type) => {
    setSnackbar({ message, type });
    setTimeout(() => {
      removeSnackbar();
    }, AUTO_DISMISS);
  };

  const contextValue = {
    toast,
    addSnackbar: useCallback((message, type) => addSnackbar(message, type), []),
    removeSnackbar: useCallback(() => removeSnackbar(), []),
  };

  return (
    <SnackbarContext.Provider value={contextValue}>
      {children}
      {toast && (
        <div className='h-full'>
          <Snackbar
            duration={AUTO_DISMISS}
            removeSnackbar={removeSnackbar}
            message={toast?.message}
            type={toast?.type}
          />
        </div>
      )}
    </SnackbarContext.Provider>
  );
}
