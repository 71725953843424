import bilAPI from '../api';

export const getTarbieTopics = ({ page, page_size }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/tarbie/tarbietopic/`, { params: { page, page_size } })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const postTarbieTopic = (body) => {
    return new Promise((resolve, reject) => {
      try {
        bilAPI
          .post(`/tarbie/tarbietopic/`, body)
          .then((res) => {
            resolve(res?.data);
          })
          .catch((err) => {
            reject('axios error');
          });
      } catch (error) {
        reject('system error');
      }
    });
  };

  export const patchTarbieTopic = (uuid, body) => {
    return new Promise((resolve, reject) => {
      try {
        bilAPI
          .patch(`/tarbie/tarbietopic/${uuid}`, body)
          .then((res) => {
            resolve(res?.data);
          })
          .catch((err) => {
            reject('axios error');
          });
      } catch (error) {
        reject('system error');
      }
    });
  };
  
  export const deleteTarbieTopic = (uuid) => {
    return new Promise((resolve, reject) => {
      try {
        bilAPI
          .delete(`/tarbie/tarbietopic/${uuid}`)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject('axios error');
          });
      } catch (error) {
        reject('system error');
      }
    });
  };
  