import bilAPI from '../api';

export const getSubjects = (event, page_size) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/education/subjects/`, { params: { event: event, page_size } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getOlympiadSubjects = () => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/education/olympiad_subjects/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getAdditionalSubjects = (event) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/education/additional_subjects/`, {
          params: { event: event, page_size: 50 },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
