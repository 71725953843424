import bilAPI from '../api';

export const getChildren = ({ parent_uuid, page, page_size }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/auth/accounts/${parent_uuid}/get_children/`, {
          params: { page, page_size },
        })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject('system error');
    }
  });
};
