import bilAPI from '../api';

export const getResponsibilityReports = () => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/responsibility_task/responsibility_task_reports', {
          params: { page_size: 50 },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const patchResponsibilityReports = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/responsibility_task/responsibility_task_reports/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};
