import bilAPI from '../api';

export const getEducationInfo = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/school/account_education_info/', { params: { account: uuid } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const postEducationInfo = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post('/school/account_education_info/', body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const patchEducationInfo = (educationUuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/school/account_education_info/${educationUuid}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const deleteEducationInfo = (educationUuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/school/account_education_info/${educationUuid}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
