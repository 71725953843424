import React from 'react';

const FormInput = React.forwardRef(
  (
    { name, label, required, error, textCenter, className, bgWhite, ...rest },
    ref,
  ) => {
    return (
      <div className='flex flex-col justify-start'>
        <label htmlFor={name} className='flex flex-row gap-1 mb-1'>
          {label}
          <span className={`text-error`}>{required && '*'}</span>
        </label>
        <input
          name={name}
          className={`px-3 py-2 ${
            bgWhite ? 'bg-white' : 'bg-sheet-gray'
          } shadow-sm ${className} focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-lg focus:ring-1 ${
            textCenter && 'text-center'
          } ${error && 'ring-1 ring-error'} `}
          {...rest}
          ref={ref}
        />
        {error && <span className='mt-1 text-error'>{error}</span>}
      </div>
    );
  },
);

export default FormInput;
