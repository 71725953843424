import React from 'react';

const FormTextArea = React.forwardRef(
  (
    {
      className,
      label,
      placeholder,
      required,
      type,
      onChange,
      value,
      readOnly,
      textCenter,
      rows,
      cols,
      bgWhite,
      defaultValue,
      maxLength,
      error,
      ...rest
    },
    ref,
  ) => {
    return (
      <div className='flex flex-col items-start'>
        <label className='flex flex-row gap-1 mb-1 font-bold'>
          {label}
          <span className={`text-error`}>{required && '*'}</span>
        </label>
        <textarea
          type={type}
          className={`px-3 py-2 ${
            bgWhite ? 'bg-white' : 'bg-sheet-gray'
          } shadow-sm ${className} focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-lg focus:ring-1 ${
            textCenter && 'text-center'
          } ${error && 'ring-1 ring-error'}`}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          defaultValue={defaultValue}
          readOnly={readOnly}
          rows={rows}
          cols={cols}
          maxLength={maxLength}
          ref={ref}
          {...rest}
        />
        {error && <span className='mt-1 text-error'>{error}</span>}
      </div>
    );
  },
);

export default FormTextArea;
