import bilAPI from '../api';

export const getRoles = ({ uuid, page, page_size }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/auth/roles/${uuid ? uuid + '/' : ''}`, {
          params: { page, page_size },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getRolesRelated = ({ uuid, page, page_size, account }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/auth/role_relateds/${uuid ? uuid + '/' : ''}`, {
          params: { page, page_size, account },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const postRolesReleted = ({ body }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/auth/role_relateds/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const patchRolesRelated = ({ uuid, body }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/auth/role_relateds/${uuid}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const deleteRolesRelated = ({ uuid, body }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/auth/role_relateds/${uuid}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
