import bilAPI from '../api';

export const getEvaluationSettings = (params) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/account_performance/evaluation_setting/', params)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject('system error');
    }
  });
};
