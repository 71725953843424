import bilAPI from '../api';

export const getSchoolsSkillStats = ({ position, course, region }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/courses/schools_course_stats/`, {
          params: { position, course, region },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getTeachersSkillStats = ({ position, course, school }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/courses/teachers_course_stats/`, {
          params: { position, course, school },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getSkillStats = ({ region, school, position }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/courses/courses_stats/`, {
          params: { region, school, position },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
