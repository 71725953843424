import bilAPI from '../api';

export const getTarbieTasks = ({
  term,
  calculate_average,
  month,
  region,
  group,
  start_date,
  end_date,
  academic_year,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/tarbie/tarbie_task/`, {
          params: {
            calculate_average,
            month,
            term,
            region,
            group,
            start_date,
            end_date,
            academic_year,
          },
        })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject('system error');
    }
  });
};
