import React from 'react';

export default function FormButton({
  text,
  color,
  loading,
  children,
  Icon,
  px = 5,
  py = 1,
  overflow,
  className,
  custom,
  disabled,
  ...rest
}) {
  return (
    <button
      disabled={disabled || loading}
      className={`relative inline-flex items-center justify-center h-10 px-${px} py-${py} gap-1 ${
        !overflow && 'overflow-hidden'
      } font-medium rounded-lg group 
        transition duration-100 ease-in 
        disabled:bg-opacity-0 disabled:ring-1 disabled:ring-secondary disabled:text-secondary disabled:cursor-default
      ${
        color === 'error'
          ? 'text-error bg-error bg-opacity-10 hover:text-white hover:bg-opacity-100'
          : color === 'error-outline'
          ? 'text-error ring-1 ring-error hover:bg-gray-200'
          : color === 'error-solid'
          ? 'bg-error text-white hover:text-white hover:brightness-75'
          : color === 'secondary'
          ? 'text-secondary bg-secondary bg-opacity-10 hover:text-white hover:bg-opacity-100'
          : color === 'secondary-outline'
          ? 'text-secondary ring-1 ring-secondary hover:bg-gray-200'
          : color === 'secondary-solid'
          ? 'bg-secondary  text-white hover:text-white hover:brightness-75'
          : color === 'success'
          ? 'text-success bg-success bg-opacity-10 hover:text-white hover:bg-opacity-100'
          : color === 'success-outline'
          ? 'text-success ring-1 ring-success hover:bg-gray-200'
          : color === 'success-solid'
          ? 'bg-success  text-white hover:text-white hover:brightness-75'
          : color === 'primary'
          ? 'text-white bg-primary hover:bg-blue-700'
          : color === 'primary-outline'
          ? 'text-primary ring-1 ring-primary hover:bg-gray-200'
          : color === 'primary-solid'
          ? 'bg-primary  text-white hover:text-white hover:brightness-75'
          : color === 'warning'
          ? 'text-white bg-warning hover:bg-orange-700'
          : color === 'warning-outline'
          ? 'text-warning ring-1 ring-warning hover:bg-gray-200'
          : color === 'success-dark'
          ? 'text-white bg-success bg-opacity-90 hover:bg-opacity-80'
          : custom
          ? `${custom}`
          : 'text-primary bg-primary bg-opacity-10 hover:text-white hover:bg-opacity-100'
      } ${className}`}
      {...rest}
    >
      {loading ? (
        <svg
          role='status'
          className='inline m-auto w-6 h-6 text-cyan-800 animate-spin'
          viewBox='0 0 100 101'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
            fill='#E5E7EB'
          />
          <path
            d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
            fill='currentColor'
          />
        </svg>
      ) : Icon ? (
        <Icon className='h-5 w-6' />
      ) : undefined}
      {/* {loading ? (
      <svg
        role='status'
        className='inline m-auto w-6 h-6 text-cyan-800 animate-spin'
        viewBox='0 0 100 101'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
          fill='#E5E7EB'
        />
        <path
          d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
          fill='currentColor'
        />
      </svg>
    ) :  */}
      {children ? children : text}
    </button>
  );
}
