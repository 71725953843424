import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { getChildren } from '../../services/children/childrenService';
import { ChildrenContext } from '../../utils/contexts/ChildrenContext';
import { UserContext } from '../../utils/contexts/UserContext';
import ChoiceChip from '../Chips/ChoiceChip/NavbarChoiceChip';

const ChildrenChips = () => {
  const [t] = useTranslation();
  const { user } = useContext(UserContext);
  const { children, setChildren, currentChild, setCurrentChild } =
    useContext(ChildrenContext);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    getChildren({ parent_uuid: user?.uuid })
      .then((res) => {
        setChildren(res);
        if (!user?.positions?.length) setCurrentChild(res?.[0]?.uuid);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className='px-4 flex flex-row gap-4 items-center pt-2'>
      {user?.positions?.length ? (
        <ChoiceChip
          label={t('navigation.my_page')}
          onClick={() => {
            setCurrentChild(undefined);
            history.push('/');
          }}
          selected={currentChild === undefined}
        />
      ) : undefined}
      {children?.map((c) => (
        <ChoiceChip
          key={c.uuid}
          label={c?.first_name}
          onClick={() => {
            setCurrentChild(c?.uuid);
            history.push('/');
          }}
          selected={currentChild === c?.uuid}
        />
      ))}
    </div>
  );
};

export default ChildrenChips;
