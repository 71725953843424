import bilAPI from '../api';

export const getTasks = ({
  calculate_average,
  periods,
  region,
  academic_year,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/olympiad/tasks`, {
          params: { calculate_average, periods, region, academic_year },
        })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject('system error');
    }
  });
};
