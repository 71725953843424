import bilAPI from '../api';

export const getQuestions = ({ lessonUuid, question_content_part }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/courses/questions/`, {
          params: {
            lesson: lessonUuid,
            question_content_part,
          },
        })
        .then((res) => {
          resolve(res.data.results);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const createQuestion = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post('/courses/questions/', body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const updateQuestion = (questionUuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/courses/questions/${questionUuid}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const removeQuestion = (questionUuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/courses/questions/${questionUuid}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const answerQuestion = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post('/courses/answer_question/', body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getUserQuestionAnswers = (accountUuid, questionUuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/courses/account_question_answer/`, {
          params: {
            account: accountUuid,
            question: questionUuid,
          },
        })
        .then((res) => {
          resolve(res.data.results);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getUserResults = (lessonUuid, accountUuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/courses/lesson_account_exam/`, {
          params: {
            account: accountUuid,
            lesson: lessonUuid,
          },
        })
        .then((res) => {
          resolve(res.data.results);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
