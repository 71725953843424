import bilAPI from '../api';

export const getRequestsToCreateStudent = ({ page, page_size, school }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/school_class/requests_to_create_student/`, {
          params: {
            page,
            page_size,
            school,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getRequestToCreateStudent = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/school_class/requests_to_create_student/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const deleteRequestToCreateStudent = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/school_class/requests_to_create_student/${uuid}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const postRequestToCreateStudent = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post('/school_class/requests_to_create_student/', body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const patchRequestToCreateStudent = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/school_class/requests_to_create_student/${uuid}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getRequestsToCreateStudentStats = ({ page, page_size }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/school_class/request_to_create_student_stats_api/`, {
          params: {
            page,
            page_size,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const exportProblemStudets = (params) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/school_class/problem_students_stats_excel_api/', { params })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};
