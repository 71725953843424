import bilAPI from '../api';

export const getResults = ({
  uuid,
  exam_collection,
  account,
  school,
  school_class,
  grade,
  name,
  testbilik_student_id,
  page,
  page_size,
  ordering,
  data,
  fields,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/exams/account_exam_result/${exam_collection}/`, {
          params: {
            uuid,
            exam_collection,
            account,
            school,
            school_class,
            grade,
            name,
            testbilik_student_id,
            page,
            page_size,
            ordering,
            data,
            fields,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getTeacherResults = ({
  uuid,
  account,
  exam_collection,
  school,
  domain_subject,
  page,
  page_size,
  ordering,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/exams/teacher_account_exam_overall/${exam_collection}/`, {
          params: {
            uuid,
            account,
            school,
            domain_subject,
            page,
            page_size,
            ordering,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getExamResults = ({
  exam_collection,
  account,
  exam,
  school,
  grade,
  page,
  page_size,
  not_correct,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/exams/exams_results/`, {
          params: {
            exam_collection,
            account,
            exam,
            school,
            grade,
            page,
            page_size,
            not_correct,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getExamResult = (id) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/exams/exams_results/${id}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const generateResults = ({
  exam_collection,
  school,
  grade,
  data,
  fields,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/exams/generate_school_results/${exam_collection}/`, null, {
          params: {
            school,
            grade,
            data,
            fields,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const generateKboResults = ({ exam_collection, school, grade }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/exams/export_kbo_exam_results/${exam_collection}/`, null, {
          params: {
            school,
            grade,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const generateKboFinalResults = ({ exam_collection, school, grade }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/exams/export_kbo_final_results/${exam_collection}/`, null, {
          params: {
            school,
            grade,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const exportTeacherExamResults = ({
  exam_collection,
  school,
  subject,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/exams/export_teacher_exam_results/${exam_collection}/`, {
          params: {
            school,
            domain_subject: subject,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getKboResults = ({
  uuid,
  exam_collection,
  account,
  school,
  school_class,
  grade,
  name,
  page,
  page_size,
  ordering,
  data,
  fields,
}) => {
  return new Promise((resolve, reject) => {
    console.log('data', data);
    console.log('fields', fields);
    try {
      bilAPI
        .get(`/exams/kbo_exam_result_overall/${exam_collection}/`, {
          params: {
            uuid,
            exam_collection,
            account,
            school,
            school_class,
            grade,
            name,
            page,
            page_size,
            ordering,
            data,
            fields,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getKboFinalResults = (exam_collection, params) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/exams/kbo_final_results/${exam_collection}/`, {
          params,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const postKboFinalResults = (exam_collection, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/exams/kbo_final_results_update/${exam_collection}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const patchIsDisqualified = (account, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/exams/update_is_disqualified/${account}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};
