import bilAPI from '../api';

export const getStudents = ({
  uuid,
  school,
  school_class,
  grade,
  olympiad_subject,
  testbilik_student_id,
  name,
  page,
  page_size,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/school_class/students/`, {
          params: {
            uuid,
            school,
            school_class,
            grade,
            olympiad_subject,
            testbilik_student_id,
            name,
            page,
            page_size,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const createStudent = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post('/school_class/create_student/', body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const generateStudentsPasswords = (params) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/school_class/student_passwords_excel_create/', {
          params,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const generateParentsPasswords = ({ school_class }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/school_class/parent_passwords_excel_create/', {
          params: { school_class },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const generateStudentsInfo = (params) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/school_class/student_info_excel_create/', {
          params,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
