import bilAPI from '../api';

export const getOlympiadRatingByAwards = ({
  academic_year,
  participated_grade,
  subject,
  school_uuid,
  olympiad,
  page,
  page_size,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/olympiad/school_olympiad_results_by_awards/${academic_year}`, {
          params: {
            academic_year,
            participated_grade,
            subject,
            school_uuid,
            olympiad,
            page,
            page_size,
          },
        })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getRegionalOlympiadRatingByAwards = ({
  academic_year,
  participated_grade,
  subject,
  olympiad,
  page,
  page_size,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/olympiad/regional_olympiad_results_by_awards/${academic_year}`, {
          params: {
            academic_year,
            participated_grade,
            subject,
            olympiad,
            page,
            page_size,
          },
        })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getAllSchoolsOlympiadRatingByAwards = ({
  academic_year,
  participated_grade,
  subject,
  olympiad,
  page,
  page_size,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(
          `/olympiad/all_school_olympiad_results_by_awards/${academic_year}`,
          {
            params: {
              academic_year,
              participated_grade,
              subject,
              olympiad,
              page,
              page_size,
            },
          },
        )
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject('system error');
    }
  });
};
