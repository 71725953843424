import bilAPI from '../api';

export const getCalendarEvents = ({
  uuid,
  academic_year,
  name,
  receiver,
  planning_date,
  page,
  page_size,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/common/calendar_event/`, {
          params: {
            uuid,
            academic_year,
            name,
            receiver,
            planning_date,
            page,
            page_size,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const postCalendarEvent = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/common/calendar_event/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const patchCalendarEvent = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/common/calendar_event/${uuid}`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const deleteCalendarEvent = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/common/calendar_event/${uuid}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
