import bilAPI from '../api';

export const getTarbieSchoolsReports = ({
  term,
  page,
  page_size,
  ordering,
  month,
  region,
  school,
  group,
  start_date,
  end_date,
  academic_year,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/tarbie/school_tarbie_report_result/`, {
          params: {
            term,
            page,
            page_size,
            ordering,
            month,
            region,
            school,
            group,
            start_date,
            end_date,
            academic_year,
          },
        })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject('system error');
    }
  });
};
