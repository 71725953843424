import bilAPI from '../api';

export const getOlympiadResults = (params) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/olympiad/olympiad_result/`, {
          params,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const postOlympiadResult = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/olympiad/olympiad_result/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const patchOlympiadResult = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/olympiad/olympiad_result/${uuid}`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const deleteOlympiadResult = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/olympiad/olympiad_result/${uuid}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getOlympiadExtraFields = ({ olympiad, page, page_size }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/olympiad/extra_fields/`, {
          params: {
            olympiad,
            page,
            page_size,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getOlympiadDropdownValues = ({
  field,
  is_main,
  page,
  page_size,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/olympiad/dropdown_values/`, {
          params: {
            field,
            is_main,
            page,
            page_size,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
