import React, { useContext, useRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CameraIcon, QuestionMarkCircleIcon } from '@heroicons/react/outline';
import Modal from '../Modals/Modal';
import FormInput from '../FormInput/FormInput';
import { UserContext } from '../../utils/contexts/UserContext';
import useContactInfo from '../../utils/hooks/useContactInfo';
import useSnackbar from '../../utils/hooks/useSnackbar';
import FormTextArea from '../FormInput/FormTextArea';
import { postTechSupportMessage } from '../../services';
import FormButton from '../Buttons/FormButton';

export default function SupportModal({ collapsed }) {
  const [open, setOpen] = useState(false);
  const [t] = useTranslation();
  const { user } = useContext(UserContext);
  const { contactInfo } = useContactInfo(user?.uuid);
  const { addSnackbar } = useSnackbar();
  const fileRef = useRef();
  const [image, setImage] = useState();

  const {
    formState: { errors },
    register,
    handleSubmit,
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    const body = new FormData();
    body.append('email', data.email);
    body.append('message', data.message);
    if (image) body.append('image', image);

    postTechSupportMessage(body)
      ?.then(() => {})
      ?.finally(() => {
        reset();
        addSnackbar(t('profile_page.checklist.done'), 'success');
      });
    setOpen(false);
  };

  const close = () => {
    fileRef.current.value = '';
    reset({ message: '', email: contactInfo?.email });
    setImage();
    setOpen(false);
  };

  const onImageChange = (e) => {
    if (e.target.files[0]) {
      if (e.target.files[0].size / 1024 / 1024 > 10) {
        addSnackbar(t('skills.lessons.file_error'), 'error');
        return;
      }
      setImage(e.target.files[0]);
    }
  };

  return (
    <>
      <div className='flex flex-col font-bold '>
        <div
          onClick={() => setOpen((prev) => !prev)}
          className={`w-full rounded-md ml-4 pr-4 text-red-400 hover:text-primary cursor-pointer`}
        >
          <div
            className={`flex flex-row gap-4 items-center ${
              collapsed ? 'justify-center' : 'mr-24 ml-4'
            }`}
          >
            <QuestionMarkCircleIcon className='h-6 flex-shrink-0' />
            {!collapsed && t('navigation.support')}
          </div>
        </div>
      </div>
      <Modal
        open={open}
        setOpen={setOpen}
        onClose={() => close()}
        ConfirmType='success-dark'
        headerText={t('navigation.support')}
        confirmText={t('buttons.submit')}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='flex flex-col w-window-4/10'>
          <span className='text-xl font-bold mb-2'>{t('support.help')}</span>
          <FormInput
            defaultValue={contactInfo?.email}
            label={t('profile_page.contacts.email')}
            required
            className='border w-[50vw]'
            error={errors?.email?.message}
            bgWhite
            {...register('email', {
              value: contactInfo?.email,
              required: 'Please enter E-mail',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Not valid E-mail',
              },
            })}
          />
          <FormTextArea
            label={t('support.message')}
            required
            className='border'
            rows={7}
            error={errors?.message?.message}
            bgWhite
            {...register('message', {
              required: 'Please enter message to support',
            })}
          />
          <div className='flex flex-row justify-start'>
            <FormButton
              className='mt-2 max-w-fit'
              Icon={CameraIcon}
              text={t('buttons.upload')}
              color='primary'
              onClick={() => fileRef.current.click()}
            />
            <input
              hidden
              type='file'
              accept='.png, .jpg, .jpeg'
              ref={fileRef}
              onChange={(e) => onImageChange(e)}
            />
          </div>
          <div className='flex flex-row justify-start'>
            {!!fileRef?.current?.files[0] && fileRef?.current?.files[0]?.name}
          </div>
        </div>
      </Modal>
    </>
  );
}
