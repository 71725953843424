import bilAPI from '../api';

export const getAcademicWeeks = ({ academicYear, academicTerm, month }) => {
  return new Promise((resolve, reject) => {
    let academic_year = academicYear;
    let academic_tearm = academicTerm;
    try {
      bilAPI
        .get(`/education/academic_week/`, {
          params: { academic_year, academic_tearm, month },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
