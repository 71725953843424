import bilAPI from '../api';

export const getClassRelateds = ({
  account,
  school_class,
  school_groups,
  page,
  page_size,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/school_class/school_class_related/`, {
          params: {
            account,
            school_class,
            school_groups,
            page,
            page_size,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const postClassRelated = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post('/school_class/school_class_related/', body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const patchClassRelated = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/school_class/school_class_related/${uuid}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};
