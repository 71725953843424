import bilAPI from '../api';

export const getRatings = (
  examCollection,

  {
    city,
    school,
    schoolId,
    schoolType,
    school_id,
    region,
    name,
    page,
    page_size,
    grade,
    ordering,
    data,
    fields,
    filter_by,
  },
) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/exams/school_exam_overall/${examCollection}/`, {
          params: {
            city,
            school,
            schoolId,
            schoolType,
            school_id,
            region,
            name,
            page,
            page_size: page_size || 60,
            grade,
            ordering,
            data,
            fields,
            filter_by,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getAllRatings = (
  examCollection,

  {
    city,
    school,
    schoolType,
    region,
    name,
    page,
    page_size,
    grade,
    ordering,
    data,
    fields,
  },
) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/exams/all_school_exam_overall/${examCollection}/`, {
          params: {
            city,
            school,
            schoolType,
            region,
            name,
            page,
            page_size: page_size || 60,
            grade,
            ordering,
            data,
            fields,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getRating = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/exams/account_exam_rating_retrieve/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const patchRating = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/exams/account_exam_rating_retrieve/${uuid}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getSchoolRating = (
  examCollection,
  {
    // school,
    // schoolId,
    school_uuid,
    name,
    page,
    page_size,
    grade,
    ordering,
    data,
    fields,
  },
) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/exams/school_exam_rating/${examCollection}/`, {
          params: {
            // school,
            // schoolId,
            school_uuid,
            name,
            page,
            page_size: page_size || 60,
            grade,
            ordering,
            data,
            fields,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getStudentRating = ({ exam_collection, student_uuid }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/exams/student_rating/${exam_collection}`, {
          params: { student_uuid },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getStudentAllExamRating = ({
  exam_collection,
  student_uuid,
  subject_uuid,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/exams/student_all_exam_rating/${exam_collection}`, {
          params: { student_uuid, subject_uuid },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
