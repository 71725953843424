import React, { useState } from 'react';
import Sidebar from './Sidebar';
import Navbar from './Navbar';

const Scaffold = ({ children, user, setUser }) => {
  const [collapsed, setCollapsed] = useState(false);

  if (user) {
    return (
      <div className='flex flex-row bg-sheet text-xs lg:text-sm xl:text-base'>
        <Sidebar
          user={user}
          setUser={setUser}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
        />

        <div
          className={`flex flex-col transition-all duration-200 ease-in-out ${
            collapsed ? 'w-window-collapsed' : 'w-window'
          }`}
        >
          <Navbar user={user} />

          <div className='border rounded-3xl ml-8 mt-2 h-window-wrapper overflow-y-auto overflow-x-auto'>
            {children}
          </div>
        </div>
      </div>
    );
  }

  return children;
};

export default Scaffold;
