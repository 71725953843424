import bilAPI from '../api';

export const getAcademicGoalTasks = ({
  page,
  page_size,
  academic_sub_goal,
  academic_goal,
  academic_year,
  indecator,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/education/academic_goal_task/`, {
          params: {
            page,
            page_size,
            academic_sub_goal,
            academic_goal,
            academic_year,
            indecator,
          },
        })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
