import bilAPI from '../api';

export const getMyTeacherPositionsInfo = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(
          '/school/account_teacher_positions_info/get_my_teacher_positions/',
          {
            params: {
              account: uuid,
            },
          }
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getTeacherPositionsInfo = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/school/account_teacher_positions_info/`, {
          params: { account: uuid },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getUserTeacherPositionsInfo = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/school/account_teacher_positions_info/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const putTeacherPositionsInfo = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .put(`/school/account_teacher_positions_info/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const patchTeacherPositionsInfo = (uuidOfPosition, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(
          `/school/account_teacher_positions_info/${uuidOfPosition}/`,
          body
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const deleteTeacherPositionsInfo = (uuidOfPosition) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/school/account_teacher_positions_info/${uuidOfPosition}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const postTeacherPositionsInfo = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/school/account_teacher_positions_info/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
