import bilAPI from '../api';

export const getSalaryHistory = ({
  year,
  month,
  school,
  iin,
  page,
  page_size,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/salary/salary_history/`, {
          params: { year, month, school, iin, page, page_size },
        })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
