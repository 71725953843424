import bilAPI from '../api';

export const getDomains = ({ page, page_size }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/account_performance/domains/', {
          params: { page, page_size },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject('system error');
    }
  });
};
