import bilAPI from '../api';

export const getSSOPStatus = ({ customer }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/auth/ssop_status/`, { params: { customer } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const postSSOPStatus = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/auth/ssop_status/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};
