import bilAPI from "../api";

export const getAnswerKeys = () => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/exams/answer_key/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("axios error");
        });
    } catch (error) {
      reject("system error");
    }
  });
};

export const patchAnswerKey = ({ uuid, body }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/exams/answer_key/${uuid}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("axios error");
        });
    } catch (error) {
      reject("system error");
    }
  });
};

export const postAnswerKey = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/exams/answer_key/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("axios error");
        });
    } catch (error) {
      reject("system error");
    }
  });
};
