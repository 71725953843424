import bilAPI from '../api';

export const getSchoolStatusSettings = ({ page_size, academic_year }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/education/schools_settings/`, {
          params: { page_size, academic_year },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getSchoolSetting = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/education/schools_settings/${uuid}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const patchSchoolSetting = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/education/schools_settings/${uuid}`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getAcademicGoalSettings = ({ page_size, academic_year }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/education/academic_goal_setting/`, {
          params: { page_size, academic_year },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const patchAcademicGoalSetting = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/education/academic_goal_setting/${uuid}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
