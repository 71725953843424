import React from 'react';
import { XIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import FormButton from '../Buttons/FormButton';

const Modal = ({
  className,
  children,
  open,
  setOpen,
  headerText,
  HeaderIcon,
  confirmText,
  ConfirmIcon,
  ConfirmType = 'primary',
  onSubmit,
  onClose,
  leaveOpenOnClose,
  loading,
}) => {
  const [t] = useTranslation();
  return (
    <div
      className={`${
        open ? '' : 'hidden'
      } ${className} overflow-y-auto overflow-x-hidden fixed flex right-0 left-0 z-50 justify-center items-center md:inset-0 sm:h-full bg-black bg-opacity-60`}
    >
      <div className='px-4 h-full md:h-auto'>
        <div className='flex flex-col bg-white rounded-lg p-6 gap-12 drop-shadow-lg'>
          <div className='flex flex-wrap justify-between'>
            <div className='flex flex-row gap-2'>
              {HeaderIcon && <HeaderIcon className='h-6 text-primary' />}
              <h6 className='text-xl font-semibold self-end'>{headerText}</h6>
            </div>
            <XIcon
              className='h-6 text-secondary cursor-pointer'
              onClick={() => {
                if (onClose) onClose();
                if (!leaveOpenOnClose) setOpen(false);
              }}
            />
          </div>
          <div className='flex items-center'>{children}</div>
          <div className='flex flex-row gap-4 justify-end'>
            <FormButton
              text={t('modal.cancel')}
              type='button'
              color='secondary'
              onClick={() => {
                if (onClose) onClose();
                if (!leaveOpenOnClose) setOpen(false);
              }}
            />
            <FormButton
              text={confirmText}
              type='button'
              color={ConfirmType}
              Icon={ConfirmIcon}
              loading={loading}
              onClick={() => onSubmit()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
