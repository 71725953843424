import bilAPI from '../api';

export const getGrades = ({
  school,
  class_type,
  class_type_code,
  class_type_codes,
  page,
  page_size,
  grade,
  grade_gte,
  grade_lte,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/school_class/school_class/`, {
          params: {
            school,
            class_type,
            class_type_code,
            class_type_codes,
            page,
            page_size,
            grade,
            grade_gte,
            grade_lte,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getGrade = (id) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/school_class/school_class/${id}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const deleteGrade = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/school_class/school_class/${uuid}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const postGrade = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post('/school_class/school_class/', body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const patchGrade = (gradeUuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/school_class/school_class/${gradeUuid}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};
