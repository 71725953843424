import bilAPI from '../api';

export const getLanguagesLevelsInfo = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/school/account_languages_levels_info/', {
          params: {
            account: uuid,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const postLanguagesLevelsInfo = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post('/school/account_languages_levels_info/', body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const patchLanguagesLevelsInfo = (uuidOfLanguageLevel, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(
          `/school/account_languages_levels_info/${uuidOfLanguageLevel}/`,
          body,
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const deleteLanguagesLevel = (uuidOfLanguageLevel) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/school/account_languages_levels_info/${uuidOfLanguageLevel}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
