import bilAPI from '../api';

export const getQundylyqSchedules = ({ page, page_size }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/tarbie/school_qundylyk_schedule/`, {
          params: { page, page_size },
        })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const patchQundylyqSchedule = (id, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/tarbie/school_qundylyk_schedule/${id}`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
