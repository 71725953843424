import bilAPI from '../api';

export const getAcademicGoalTotalAverage = ({
  calculate_average,
  indecator,
  academic_year,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/education/academic_goal/`, {
          params: { calculate_average, indecator, academic_year },
        })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
