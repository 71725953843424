import bilAPI from '../api';

export const getAccounts = ({
  uuid,
  page,
  page_size,
  name,
  is_in_simulation,
  school,
  role,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/auth/accounts/${uuid ? uuid + '/' : ''}`, {
          params: {
            page,
            page_size,
            name,
            is_in_simulation,
            school,
            role,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const patchAccount = ({ uuid, body }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/auth/accounts/${uuid}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
