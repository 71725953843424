import bilAPI from '../api';

export const getAchievements = (uuid, achievementUuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/common/account_achievements/', {
          params: { account: uuid, uuid: achievementUuid },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const postAchievement = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post('/common/account_achievements/', body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const patchAchievement = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/common/account_achievements/${uuid}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const deleteAchievement = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/common/account_achievements/${uuid}/`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getEventTypes = (name) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/common/event_types/', { params: { name: name } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getEvents = (params) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/common/events/', { params: params })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getEventCategories = (name) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/common/event_categories/', { params: { name: name } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
