import bilAPI from '../api';

export const getConsultations = ({
  recipient,
  psychologist,
  page,
  page_size,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/psychology/consultations/`, {
          params: { recipient, psychologist, page, page_size },
        })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const postConsultation = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/psychology/consultations/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const patchConsultation = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/psychology/consultations/${uuid}`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const deleteConsultation = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/psychology/consultations/${uuid}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
