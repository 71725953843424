import bilAPI from '../api';

export const getAcademicGoalTaskRegionReports = ({
  tasks,
  indecator,
  page,
  page_size,
  ordering,
  academic_year,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/education/academic_goal_task_region_report`, {
          params: {
            tasks,
            page,
            page_size,
            indecator,
            ordering,
            academic_year,
          },
        })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
