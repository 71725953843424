import bilAPI from '../api';

export const getPsychologyTests = () => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/psychology/tests/`)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getPsychologyTestById = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/psychology/tests/${uuid}/`)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const postPsychologyTest = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/psychology/tests/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const patchPsychologyTest = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/psychology/tests/${uuid}/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const deletePsychologyTest = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/psychology/tests/${uuid}/`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getPsychologistSchoolClasses = (test) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/psychology/school_classes', { params: { test } })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const patchTestAvailabilityForClass = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/psychology/update_test_availability_for_class/${uuid}`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const postCompleteTest = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/psychology/complete_test/${uuid}/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
