const getLocalRefreshToken = () => {
  const refreshToken = localStorage.getItem('refreshToken');
  return refreshToken;
};

const getLocalAccessToken = () => {
  const accessToken = localStorage.getItem('accessToken');
  return accessToken;
};

const updateLocalAccessToken = (token) => {
  localStorage.setItem('accessToken', token);
};

const removeTokens = () => {
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('accessToken');
};

const TokenService = {
  getLocalRefreshToken,
  getLocalAccessToken,
  updateLocalAccessToken,
  removeTokens,
};

export default TokenService;
