import bilAPI from '../api';

export const getSchool = (schoolUuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/education/schools/${schoolUuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getAllSchools = (
  region,
  org_type,
  system_type,
  group,
  region_codes,
) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/education/schools/all_schools/', {
          params: {
            region,
            org_type,
            system_type,
            region_codes,
            group,
            page_size: 80,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const patchSchool = (schoolUuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/education/schools/${schoolUuid}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getSchoolsFillingInfo = (params) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/school/schools_filling_overall/', {
          params,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getSchoolsFillingExcel = (params) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/school/school_accomplishment_overall_excel/', {
          params,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};
