import bilAPI from '../api';

export const getTat = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/salary/salary_profile/${uuid}/get_tat/`)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
