import bilAPI from '../api';

export const postCourseRating = (body) => {
  return new Promise((resolve, reject) => {
      try {
          bilAPI
          .post('/courses/course_rating/', body)
          .then((res) => {
              resolve(res.data);
          })
          .catch((err) => {
              reject(err);
          });
      } catch (error) {
          reject(error);
      }
  });
};

export const getCourseRating = (params) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/courses/course_rating/`, { params })
        .then((res) => {
          resolve(res.data.results);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};