import bilAPI from '../api';

export const getTarbieReportsOfClass = ({
  uuid,
  weeks,
  group,
  academic_year,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/tarbie/school_class_tarbie_report/${uuid}/`, {
          params: { weeks, group, academic_year },
        })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const patchTarbieReport = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/tarbie/tarbie_report/${uuid}/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
