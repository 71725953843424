import bilAPI from '../api';

export const getSchoolsReports = ({
  periods,
  tasks,
  page,
  page_size,
  ordering,
  subject,
  region,
  school,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/olympiad/olympiad_school_reports`, {
          params: {
            periods,
            tasks,
            page,
            page_size,
            ordering,
            subject,
            region,
            school,
          },
        })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject('system error');
    }
  });
};
