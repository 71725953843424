import bilAPI from '../api';

export const getQundylyqTests = ({ qundylyk, page, page_size }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/tarbie/qundylyk_test/`, {
          params: { qundylyk, page, page_size },
        })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const postQundylyqTest = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/tarbie/qundylyk_test/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const patchQundylyqTest = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/tarbie/qundylyk_test/${uuid}`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
