import bilAPI from '../api';

export const getAcademicGoalSchoolReports = ({
  tasks,
  page_size,
  academic_year,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/education/schools_academic_goal_task_report`, {
          params: { tasks, page_size, academic_year },
        })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
