import bilAPI from '../api';

export const changePassword = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/auth/${uuid}/change_password`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getPasswordCode = (username) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/auth/password_code/${username}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const postPasswordCode = (username, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/auth/password_code/${username}`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const postPasswordReset = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post('/auth/password_reset/', body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};
