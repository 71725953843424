import bilAPI from '../api';

export const getSections = (courseUuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/courses/sections/`, {
            params: {
                course: courseUuid
            }
        })
        .then((res) => {
          resolve(res.data.results);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getSection = (sectionUuid) => {
    return new Promise((resolve, reject) => {
      try {
        bilAPI
          .get(`/courses/sections/${sectionUuid}`
          )
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject('axios error');
          });
      } catch (error) {
        reject('system error');
      }
    });
  };

  export const createSection = (body) => {
    return new Promise((resolve, reject) => {
      try {
        bilAPI
          .post('/courses/sections/', body)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      } catch (error) {
        reject(error);
      }
    });
  };
  
  export const updateSection = (sectionUuid, body) => {
    return new Promise((resolve, reject) => {
      try {
        bilAPI
          .patch(`/courses/sections/${sectionUuid}`, body)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      } catch (error) {
        reject(error);
      }
    });
  };

  export const removeSection = (sectionUuid) => {
    return new Promise((resolve, reject) => {
      try {
        bilAPI
          .delete(`/courses/sections/${sectionUuid}`
          )
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject('axios error');
          });
      } catch (error) {
        reject('system error');
      }
    });
  };