import bilAPI from '../api';

export const getSchoolHistory = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/school_class/school_history/', { params: { account: uuid } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const postSchoolHistory = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post('/school_class/school_history/', body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const patchSchoolHistory = (educationUuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/school_class/school_history/${educationUuid}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const deleteSchoolHistory = (educationUuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/school_class/school_history/${educationUuid}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
