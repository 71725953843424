import bilAPI from '../api';

export const getSchoolSettings = (params) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/school/school_transfer_setting/`, { params })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getSubjectSettings = (params) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/school/subjects_setting/`, { params })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const postSubjectSettings = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/school/subjects_setting/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const patchSubjectSettings = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/school/subjects_setting/${uuid}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const resetAllSchoolsSimulations = () => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/school/transfer_simulation/reset/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const resetSchoolSimulation = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/school/transfer_simulation/${uuid}/reset/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const createSimulationTransfers = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/school/transfer_simulation/update/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const saveSimulationTransfers = () => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/school/transfer_simulation/save/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const exportSimulationSchoolSettings = (params) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/school/export_school_settings/', { params })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const postSimulationComment = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post('/common/comments/', body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const deleteSimulationComment = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/common/comments/${uuid}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const postArchiveTransfers = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post('/school/transfer_simulation/archive/', body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};
