import bilAPI from '../api';

export const getTeachersReports = ({ periods, tasks, page, page_size, ordering, subject, school, region}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/olympiad/olympiad_teacher_reports`, {
          params: { periods, tasks, page, page_size, ordering, subject, school, region }
        })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject('system error');
    }
  });
};
