import bilAPI from '../api';

export const getObjectives = ({
  topic,
  code,
  content,
  subject,
  grade,
  exam_collection,
  exam,
  page,
  page_size,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/education/learning_objectives/`, {
          params: {
            topic,
            code,
            content,
            subject,
            grade,
            exam_collection,
            exam,
            page,
            page_size,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
