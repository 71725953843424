import bilAPI from '../api';

export const getJobHistory = (userUuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/school/job_history/', { params: { account: userUuid } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const postJobHistory = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post('/school/job_history/', body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const patchJob = (jobId, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/school/job_history/${jobId}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const deleteJob = (jobId) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/school/job_history/${jobId}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
