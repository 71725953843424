import bilAPI from '../api';

export const getReportTasks = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/olympiad/period_tasks/${uuid}`)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const patchReportTask = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/olympiad/olympiad_teacher_task/${uuid}/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
