import { UserIcon } from '@heroicons/react/solid';
import React from 'react';

const NavbarChoiceChip = ({
  selected,
  label,
  onClick,
  type,
  className,
  disabled,
}) => {
  return (
    <button
      className={`relative inline-flex items-center justify-center h-7 px-5 py-1 gap-1 overflow-hidden font-medium rounded-xl group 
    transition duration-100 ease-in 
    disabled:cursor-default ring-1 ${
      selected ? 'bg-opacity-20' : 'bg-opacity-0 hover:bg-opacity-20'
    }
  ${
    type === 'error'
      ? 'text-error bg-error ring-error'
      : type === 'secondary'
      ? 'text-secondary bg-secondary ring-secondary'
      : type === 'success'
      ? 'text-success bg-success ring-success'
      : `text-primary bg-primary ring-primary`
  } ${className}`}
      onClick={onClick}
      disabled={selected || disabled}
    >
      <div className='flex flex-row gap-2 items-center justify-center'>
        {selected ? (
          <UserIcon
            className={`h-5 ${
              type === 'error'
                ? 'text-error'
                : type === 'secondary'
                ? 'text-secondary'
                : type === 'success'
                ? 'text-success'
                : `text-primary`
            }`}
          />
        ) : undefined}
        {label}
      </div>
    </button>
  );
};

export default NavbarChoiceChip;
